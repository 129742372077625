// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import * as bootstrap from "bootstrap";
import Swal from "sweetalert2";
import "../template";
import "../controllers";

import "boxicons/css/boxicons.css";
import "stylesheets/application.scss";

let popoverTriggerList = [].slice.call(
  document.querySelectorAll('[data-bs-toggle="popover"]')
);

popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl);
});

window?.Turbo?.setConfirmMethod((message) => {
  return new Promise((resolve, _reject) => {
    Swal.fire({
      title: "Você tem certeza?",
      text: message,
      icon: "warning",
      buttonsStyling: false,
      cancelButtonText: "Manter",
      confirmButtonText: "Sim, remover",
      customClass: {
        cancelButton: "btn btn-secondary me-2",
        confirmButton: "btn btn-danger",
      },
      reverseButtons: true,
      showCancelButton: true,
    }).then((confirmed) => {
      resolve(confirmed && confirmed.value == true);
    });
  });
});
